<template>
<div>
  <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 

  <div class="content">
    <validation-observer ref="VFormLaporanNifas">
      <b-form @submit.prevent="doSubmit()">
        <div class="card">
          <div class="card-header bg-white">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <div class="row align-items-center">
                  <div class="col-md-auto">
                    <h5 class="card-title font-weight-semibold">Laporan Nifas</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm  table-bordered mb-2">
                  <tbody>
                    <tr>
                      <th class="bg-primary">Tanggal / Jam </th>
                      <th class="bg-primary"> Keadaan umum </th>
                      <th class="bg-primary"> Mamae/Laktasi </th>
                      <th class="bg-primary">Uterus </th>
                      <th class="bg-primary">Lochea </th>
                      <th class="bg-primary">DC </th>
                      <th class="bg-primary">Lain-lain </th>
                      <th class="bg-primary">Aksi</th>
                    </tr>

                    <tr v-for="(v, k) in dataLaporan" :key="`laporan-${k}`">
                      <td>
                        <date-picker v-model="v.created_date" :default-value="v.created_date" type="datetime" value-type="format" format="YYYY-MM-DD HH:mm" @change="updateRowData(v)"></date-picker>
                      </td>
                      <td>
                        <b-form-group>
                          <b-form-radio v-model="v.keadaan_umum" :value="val.label" v-for="(val, vk) in mrKeadaanUmum" :key="`keadaan-umum-${k}-${vk}`" @change="updateRowData(v)">
                            {{ val.label }}
                          </b-form-radio>
                        </b-form-group>

                        <template v-if="v.keadaan_umum == 'Lain-lain'">
                          <b-form-input v-model="v.keadaan_umum_note" @change="updateRowData(v)" />
                          <VValidate
                            name="Catatan"
                            v-model="v.keadaan_umum_note"
                            :vid="`kun-${vk}`"
                            rules="required"
                          />
                        </template>

                        <VValidate
                          name="Keadaan Umum"
                          :vid="`keadaan-umum-${k}`"
                          v-model="v.keadaan_umum"
                          :rules="mrValidationData.keadaan_umum"
                        />
                      </td>

                      <td>
                        <b-form-group>
                          <b-form-radio v-model="v.laktasi" :value="val.label" v-for="(val, vk) in mrLaktasi" :key="`laktasi-${k}-${vk}`" @change="updateRowData(v)">
                            {{ val.label }}
                          </b-form-radio>
                        </b-form-group>

                        <template v-if="v.laktasi == 'Lain-lain'">
                          <b-form-input v-model="v.laktasi_note" @change="updateRowData(v)" />
                          <VValidate
                            name="Catatan"
                            v-model="v.laktasi_note"
                            :vid="`lakn-${vk}`"
                            rules="required"
                          />
                        </template>

                        <VValidate
                          name="Mamae/Laktasi"
                          :vid="`laktasi-${k}`"
                          :value="(v.laktasi||[]).length > 0 ? true : null"
                          :rules="mrValidationData.laktasi"
                        />
                      </td>

                      <td>
                        <b-form-group>
                          <b-form-radio v-model="v.uterus" :value="val.label" v-for="(val, vk) in mrUterus" :key="`uterus-${k}-${vk}`" @change="updateRowData(v)">
                            {{ val.label }}
                          </b-form-radio>
                        </b-form-group>

                        <VValidate
                          name="Uterus"
                          :vid="`uterus-${k}`"
                          :value="(v.uterus||[]).length > 0 ? true : null"
                          :rules="mrValidationData.uterus"
                        />
                      </td>
                      <td>
                        <b-form-group>
                          <b-form-radio v-model="v.lochea" :value="val.label" v-for="(val, vk) in mrLochea" :key="`lochea-${k}-${vk}`" @change="updateRowData(v)">
                            {{ val.label }}
                          </b-form-radio>
                        </b-form-group>

                        <VValidate
                          name="Lochea"
                          :vid="`lochea-${k}`"
                          :v-model="v.lochea"
                          :rules="mrValidationData.lochea"
                        />
                      </td>

                      <td>
                        <b-form-group>
                          <b-form-radio v-model="v.dc" :value="val.label" v-for="(val, vk) in mrDc" :key="`dc-${k}-${vk}`" @change="updateRowData(v)">
                            {{ val.label }}
                            
                            <template v-if="val.has_note && v.dc == val.label">
                              <b-form-input v-model="v.dc_note" @change="updateRowData(v)" />
                              <VValidate
                                name="Catatan"
                                v-model="v.dc_note"
                                :vid="`dcn-${vk}`"
                                rules="required"
                              />
                            </template>
                          </b-form-radio>
                        </b-form-group>


                        <VValidate
                          name="DC"
                          :vid="`dc-${k}`"
                          :value="(v.dc||[]).length > 0 ? true : null"
                          :rules="mrValidationData.dc"
                        />
                      </td>

                      <td>
                        <b-form-textarea v-model="v.other" rows="2" @change="updateRowData(v)"></b-form-textarea>
                      </td>
                      <td>
                        <a href="javascript:;" @click="deleteRow(v, k)" class="btn btn-icon btn-sm rounded-round alpha-danger border-danger text-danger-800" v-b-tooltip.hover data-popup="tooltip" title="Hapus" v-if="dataLaporan.length > 1"><i class="icon-trash"></i></a>
                      </td>
                    </tr>
                  </tbody>
                  <tr>
                    <td class="text-center" colspan="6">
                      <a href="javascript:;" class="btn btn-outline-info alpha-info text-blue-800 btn-sm" @click="addLaporan()">
                        <i class="mr-2 icon-plus2"></i>
                        <span>Tambah</span>
                      </a>
                    </td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header bg_head_panel">
                    <div class="row">
                      <div class="col-md-4">
                        <h6 class="card-title font-weight-semibold">Catatan KB</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-3">
                    <div class="row">
                      <div class="col-md-5">
                        <div class="form-group">
                          <label>Jenis KB </label>
                          <b-form-group>
                            <b-form-radio-group
                              v-model="row.jenis_kb"
                              :options="mrJenisKB"
                              value-field="value"
                              text-field="label"
                              @input="updateRow(row)"
                            >
                            </b-form-radio-group>
                          </b-form-group>
                          <template v-if="row.jenis_kb == 'OTHER'">
                            <b-form-input v-model="row.jenis_kb_note" @change="updateRow(row)" />
                            <VValidate
                              name="Catatan"
                              v-model="row.jenis_kb_note"
                              :vid="`jkb`"
                            />
                          </template>

                          <VValidate
                            name="Jenis KB"
                            v-model="row.jenis_kb"
                            :rules="mrValidation.jenis_kb"
                          />
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label class="" for="">Tanggal Pemasangan</label>
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="icon-calendar22"></i></span>
                            </div>
                            <datepicker input-class="form-control transparent" placeholder="Tanggal" class="my-datepicker" calendar-class="my-datepicker_calendar" v-model="row.install_date" @input="updateRow(row)">
                            </datepicker>
                          </div>

                          <VValidate
                            name="Tanggal Pemesangan"
                            v-model="row.install_date"
                            :rules="mrValidation.install_date"
                          />
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="text-right">
              <button type="button" class="btn" @click="$router.back()">Kembali</button>
              <button type="button" class="btn btn-outline-primary mr-2" @click="$router.go(-1)">Simpan Sementara</button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane ml-2"></i></button>
            </div>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</div>
</template>

<script>
import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import Datepicker from 'vuejs-datepicker'

import MonitoringOptions from '@/libs/MonitoringOptions.js'
import Formatter from '@/libs/Formatter.js'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import Gen from '@/libs/Gen'

import GlobalVue from '@/libs/Global'

const _ = global._

export default {
  components: {
    PatientInfo,
    Datepicker,
    DatePicker
  },

  extends: GlobalVue,

  data() {
    return {
      loading: {
        patientInfo: false
      },

      patientData: {},

      mrCriteria: [],
      mrDischargePlanning: [],
      dataLaporan: [{}],
      mrValidation: {},
      mrValidationData: {},

      mrJenisKB: [],
      mrKeadaanUmum: [],
      mrLaktasi: [],
      mrUterus: [],
      mrLochea: [],
      mrDc: [],

      row: {},

      formData: {
        discharge_planning: [],
        is_discharge: false
      },
    }
  },

  mounted() {
    this.getPatientInfo()
    this.getDataForm()
  },

  methods: {
    deleteRow(data, key){
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: "delete-laporan-nifas-data",
            id_registrasi: this.$route.params.pageSlug, 
            id: data.id
          }
        },
        "POST"
      ).then(() => {
        this.dataLaporan.splice(key, 1)
      })
    },
    addLaporan(){
      this.dataLaporan.push({})
      this.updateRowData(this.dataLaporan[this.dataLaporan.length - 1])
    },

    removeLaporan(key){
      this.dataLaporan.splice(key, 1)
    },
    updateRowData(data) {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: !data.id ? "add-laporan-nifas-data" : "update-laporan-nifas-data",
            id_registrasi: this.$route.params.pageSlug,
            ...data
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this.loading, 'patientInfo', false)
        if (!data.id) {
          this.$set(data, 'id', resp.data.id)
          this.$set(data, 'created_date', resp.data.created_date)
        }
      })
    },

    updateRow(data) {
      Gen.apiRest(
        "/do/" + this.modulePage, {
          data: {
            type: !data.id ? "add-laporan-nifas" : "update-laporan-nifas",
            id_registrasi: this.$route.params.pageSlug,
            ...data
          }
        },
        "POST"
      ).then(() => {
        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getPatientInfo() {
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/" + 'RanapPasienPerawatan', {
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          }
        },
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)

        this.$set(this.loading, 'patientInfo', false)
      })
    },

    getDataForm() {
      Gen.apiRest(
        "/get/" + this.modulePage, {
          params: {
            type: "get-form",
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(resp => {
        _.forEach(resp.data, (v, k) => { this.$set(this, k, v) })
      })
    },

    doSubmit() {
      this.$refs['VFormLaporanNifas'].validate().then(success => {
        if (!success) return false
        this.$swal.fire({
          title: 'Loading...',
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            this.$swal.showLoading()
          }
        })

        setTimeout(() => { 
          this.$swal.close()

          this.$swal({
            title: "Berhasil mengisi laporan persalinan",
            icon: 'success',
            confirmButtonText: 'Ok'
          }).then(result => {
            if (result.value) {
              this.$router.back()
            }
          })
        }, 700)
      })
    }
  },

  watch: {
    $route() {
      this.getPatientInfo()
      this.getDataForm()
    },

    "row.jenis_kb"(v){
      if(v != 'OTHER') this.$set(this, 'jenis_kb_note', null)
    }
  },

  computed: {
    Formatter() {
      return Formatter
    },

    MonitoringOptions() {
      return MonitoringOptions
    }
  },
}
</script>
